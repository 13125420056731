import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    nombre: ''
};

const mutations = {
    updateField,

    'INICIALIZAR' (state) {
        state.id = '';
        state.nombre = '';
    },
    'SET_CONTRIBUYENTE'(state, contribuyente) {
        state.id = contribuyente.id;
        state.nombre = contribuyente.nombre.trim();
    },
};

const getters = {
    getField,

    contribuyenteId(state) {
        return state.id;
    },
    contribuyente(state) {
        return state.nombre;
    }
};

const actions = {
    getContribuyente ( { commit }, numeroDocumento ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            apiAxios.get(`public/contribuyentes/${numeroDocumento}`) 
                .then(res => {
                    if (res.status == 200) {
                        commit('SET_CONTRIBUYENTE', res.data);
                        resolve();
                    } else {
                        resolve();
                    }
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}