import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

// Containers
const DefaultContainer = () => import('@/containers/Container')

// Views
/*
const Dashboard = () => import('@/views/Dashboard')
*/

// const Solicitud = () => import('@/views/Solicitud')
const Solicitud = () => import('@/views/SolicitudManual')
const SolicitudNumeroDocumento = () => import('@/views/SolicitudNumeroDocumento')
const SolicitudConsulta = () => import('@/views/SolicitudConsulta')
const SolicitudContribuyente = () => import('@/views/SolicitudContribuyente')
const SolicitudDatos = () => import('@/views/SolicitudDatos')
const Home = () => import('@/views/Home')
const Programa = () => import('@/views/Programa')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  // mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'Home',
          component: Home,
        },
        {
          path: '/programa/:id',
          name: 'Programa',
          component: Programa,
        },
        {
          path: '/solicitud/consulta',
          name: 'SolicitudConsulta',
          component: SolicitudConsulta,
        },
        {
          path: '/solicitud/datos',
          name: 'SolicitudDatos',
          component: SolicitudDatos,
        },
        {
          path: '/solicitud-identificacion/:id',
          name: 'SolicitudNumeroDocumento',
          component: SolicitudNumeroDocumento,
        },
        {
          path: '/solicitud-contribuyente',
          name: 'SolicitudContribuyente',
          component: SolicitudContribuyente,
        },
        {
          path: '/solicitud',
          name: 'Solicitud',
          component: Solicitud,
        },
        // {
        //   path: '/solicitud/:id',
        //   name: 'Solicitud',
        //   component: Solicitud,
        // },
      ]
    },
  ]
})
